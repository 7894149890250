import Http from '../containers/App/Http';
import store from '../containers/App/store'
import {
    setStatistics,
    setCategory
} from "../redux/actions/categoryActions";

export default class Category{

    getCategoryPageData(categoryPermalink){
        // FETCH THE VENUE INFO
        const formData = new FormData();
        formData.append('pc_permalink',categoryPermalink);

        return Http.post("/c/getCategoryPageData",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            await store.dispatch(setCategory(response.data.data.category));
            await store.dispatch(setStatistics(response.data.data.statistics));

            return response;
        });
    };

}