import Http from '../containers/App/Http';
import store from '../containers/App/store'
import {setProducts} from "../redux/actions/productsActions";


export default class Products{

    getAllProducts(categories, active,setInStore){
        let formData =new FormData();
        if(typeof categories !== "undefined" && categories !== null) formData.append("categories",categories);
        if(typeof active !== "undefined" && active !== null) formData.append("active",active);

        return Http.post("/ps/getAllProducts",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            if(typeof setInStore === "undefined" || setInStore ===true)
                await store.dispatch(setProducts(response.data.data));

            return response;
        });
    };

    addProduct(formData){

        return Http.post("/ps/addProduct",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            return response;
        });
    };


}